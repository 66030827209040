.ew-profile {
    .ew-bg {
        background: linear-gradient(180deg, #101010 0%, #2C6BBF 100%);
    }
    .ew-border-gradient {
        border-color: #101010 !important;
    }
    .text-black {
        color: #101010;
        font-weight: 700;
    }

    .rbt-input {
        border: none !important;
    }
    
}