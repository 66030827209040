.ew-menu {
    .ew-min-menu {
        min-height: auto;
    }
    .ew-bg {
        background: linear-gradient(180deg, #101010 0%, #2C6BBF 100%);
    }
    .ew-border-gradient {
        border-color: #101010 !important;
        border-radius: 15px;
        padding: 5px;
        input {
            border: 0px !important;
        }
    }
    .text-black {
        color: #101010;
        font-weight: 700;
    }
}
