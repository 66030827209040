.main-menu button {
    color: white !important;
    background: $gray-900 !important;
    font-size:13px !important;
}

.main-menu .sidebar-menu-sub-toggle {
    padding: 0.5rem 0.75rem !important;
}

.main-menu .sidebar-menu-sub-toggle:focus {
    border-color: none !important;
    box-shadow: none !important;
}