.ew-dashboard-header {
    background: linear-gradient(180deg, #101010 100%, #101010 0%) !important;
    //background-image: url('../../asset/images/banner.jpg');
    min-height: 16rem;
    input {
        border-radius: 10px !important;
    }
}

.ew-dashboard {
    .ew-bg {
        background: linear-gradient(180deg, #101010 0%, #2C6BBF 100%);
    }
    .ew-border-gradient {
        border-color: #101010 !important;
        border-radius: 15px;
        padding: 5px;
        input {
            border: 0px !important;
        }
    }
    .text-black {
        color: #101010;
        font-weight: 700;
    }
}
