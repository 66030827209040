.ew-login-layout {
    .bg-dark-img {
        background: url('../../asset/images/bg.jpeg');
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: fixed;        
    }
    .ew-bg {
        background: linear-gradient(180deg, #101010 0%, #2C6BBF 100%);
    }
    .ew-border-gradient {
        border-color: #101010 !important;
        border-radius: 5px;
    }
    .text-black {
        color: #101010;
        font-weight: 700;
    }
}
